<script setup>
import { ref } from "vue";
import { router } from "@inertiajs/vue3";
import { Head, Link } from "@inertiajs/vue3";
import ApplicationMark from "@/Components/ApplicationMark.vue";
import Banner from "@/Components/Banner.vue";
import SideBar from "@/Components/SideBar.vue";
import SidebarItem from "../Components/SidebarItem.vue";
import TopNavigation from "../Components/TopNavigation.vue";
import Card from "../Components/Card.vue";
import BackgroundHexagons from '@/Components/BackgroundHexagons.vue'

defineProps({
    title: String,
});

const sidebar = ref(null);

const showingNavigationDropdown = ref(false);

const switchToTeam = (team) => {
    router.put(
        route("current-team.update"),
        {
            team_id: team.id,
        },
        {
            preserveState: false,
        }
    );
};

const logout = () => {
    Inertia.post(route("logout"));
};
</script>

<template>
    <div>

        <Head :title="title" />
        <BackgroundHexagons />


        <div class="h-screen w-full">
            <div class="ml-auto w-full md:w-2/3 lg:w-3/5 sm:px-6 lg:px-8">
                <div class="flex h-full pt-[72px] ">
                    <!-- Page Heading -->
                    <div class="w-full">
                        <header v-if="$slots.header" class="pl-4 pt-4 pb-2">
                            <slot name="header" />
                        </header>

                        <!-- Page Content -->
                        <main class="w-full">
                            <slot />
                        </main>
                    </div>
                </div>
                <div id="bg-image" class="fixed left-0 bottom-0 top-[72px] select-none w-0 md:w-2/5 lg:w-2/5">
                    <img src="/superapp_bg.png"
                        alt="achtergrondafbeelding van een stukje bureau met een klein deel van een monitor in het zicht"
                        class="w-full h-full object-cover">
                    <div class="absolute bottom-0 left-0 w-full top-0 backdrop-blur">
                    </div>
                </div>

                <TopNavigation>

                </TopNavigation>
            </div>
        </div>
        <Banner />
    </div>
</template>
<style lang="css">
:root {
    --menu-height: 72px;
}

#bg-image img {
    mask-image: linear-gradient(78deg, black 66%, transparent 66%);
}
</style>