<script lang="ts" setup>
import { defineProps, computed } from 'vue';
import AppLayout from '@/Layouts/ManagerLayout.vue';
import Welcome from '@/Components/Welcome.vue';
import Card from '@/Components/Card.vue';
import dashboard_teams from '../../img/dashboard_teams.png';
import dashboard_members from '../../img/dashboard_members.png';
import dashboard_results from '../../img/dashboard_results.png';
import dashboard_history from '../../img/dashboard_history.png';

interface Team {
    name: string
    id: number
}

const props = defineProps<{
    companies: [];
    teams: Team[];
    members: number;
    gapAmounts: {
        high_high: number;
        high_mid: number;
        high_low: number;
        mid_mid: number;
        mid_low: number;
        mid_high: number;
        low_low: number;
        low_mid: number;
        low_high: number;
    }
    teamsBought: number;
    seatsBought: number;
}>();

const amounts = computed(() => {
    return {
        Talent: 0 + props.gapAmounts.high_high,
        Ontwikkelen: 0 + props.gapAmounts.high_mid + props.gapAmounts.high_low,
        Neutraal: props.gapAmounts.mid_mid + props.gapAmounts.low_mid + props.gapAmounts.mid_low,
        Onderhouden: props.gapAmounts.mid_high + props.gapAmounts.low_high,
        Mobiliteit: props.gapAmounts.low_low,
    };
});
</script>

<template>
    <AppLayout title="Dashboard">
        <template #header>
            <h2 class="font-semibold text-xl text-gray-800 leading-tight">
                Dashboard - {{ companies[0].name }}
            </h2>
        </template>

        <div class="">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div class="overflow-hidden flex justify-around">
                    <div class="flex flex-col flex-wrap gap-4 max-w-xs justify-center">
                        <Card class="bg-white">
                            <img class="mx-auto" :src="dashboard_teams">
                            <h3>Aantal teams</h3>
                            <p>{{ teams.length }} / {{ teamsBought }}</p>
                        </Card>
                        <Card class="bg-white">
                            <img class="mx-auto" :src="dashboard_members">
                            <h3>Aantal medewerkers</h3>
                            <p>{{ members }} / {{ seatsBought }}</p>
                        </Card>

                        <Card class="bg-white">
                            <img class="mx-auto" :src="dashboard_results">
                            <h3 class="my-3">Beoordelingen</h3>
                            <a class="inline-block bg-white rounded-md border border-gray-200 p-2 shadow"
                                v-for="team in teams" :key="team.id" :href="route('teams.dashboard', team.id)">{{
                                    team.name
                                }}</a>
                        </Card>
                        <Card class="bg-white">
                            <img class="mx-auto" :src="dashboard_history">
                            <h3>Geschiedenis</h3>
                        </Card>


                    </div>
                    <div class="flex flex-row py-6 px-10 border rounded-xl border-gray-300">
                        <div class="flex flex-col text-center gap-2 mr-7">
                            <h4 class="font-semibold">Beoordeling</h4>
                            <div class="border rounded-lg bg-white p-4 h-[100px]" v-for="item in Object.keys(amounts)">
                                <img class="max-w-full h-3/4 m-auto" :src="`/img/poppetje_${item.toLowerCase()}.png`"
                                    alt="">
                                <p>{{ item }}</p>
                            </div>
                        </div>
                        <div class="flex flex-col gap-2 text-center">
                            <h4 class="block font-semibold">Aantal</h4>
                            <div class="h-[100px] w-[60px] flex flex-col justify-center"
                                v-for="item, index in Object.values(amounts)" :key="index">
                                <div class="border rounded-lg bg-white p-3 grow-0">
                                    {{ item }}

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </AppLayout>
</template>
