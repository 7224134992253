<script setup>
import { ref } from "vue";
import { router } from "@inertiajs/vue3";
import { Head, Link } from "@inertiajs/vue3";
import ApplicationMark from "@/Components/ApplicationMark.vue";
import Banner from "@/Components/Banner.vue";
import SidebarItem from "../Components/SidebarItem.vue";
import TopNavigation from "../Components/TopNavigation.vue";

defineProps({
    title: String,
});

const sidebar = ref(null);

const showingNavigationDropdown = ref(false);

const logout = () => {
    Inertia.post(route("logout"));
};
</script>

<template>
    <div>

        <Head :title="title" />


        <div class="h-screen">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div class="flex h-full pt-[72px] ">
                    <!-- Page Heading -->
                    <div class="w-full">
                        <header v-if="$slots.header" class="pl-4 pt-4 pb-2">
                            <slot name="header" />
                        </header>

                        <!-- Page Content -->
                        <main class="p-3">
                            <slot />
                        </main>
                    </div>
                </div>

                <TopNavigation>
                    <a :href="route('superapp')" class="text-white pl-2 pb-2">
                        <font-awesome-icon icon=" fa-solid fa-arrow-up-from-bracket" class="mr-1" />
                        <span class="hidden sm:inline">Andere modules</span>
                    </a>

                </TopNavigation>
            </div>
        </div>
        <Banner />
    </div>
</template>
<style lang="css">
:root {
    --menu-height: 72px;
}
</style>